import React, { useState } from "react"
import SEO from "../components/seo"
import axios from "axios"
import Title from "../components/title"
import Layout from "../components/layout"
import { Col, Container, Form, ListGroup, Button } from "react-bootstrap"

const Contact = () => {
  const [data, setData] = useState({})
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleChange = e => {
    const value = Object.assign({}, data)
    value[e.target.name] = e.target.value
    setData(value)
  }

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) form.reset()
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/8428a6c7-23e9-46a6-bdf1-1bb1e62f797d",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Thank you for getting in touch!", form)
        setData({})
        setTimeout(() => {
          setServerState({
            submitting: false,
            status: null,
          })
        }, 6000)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  const activeForm = form => {
    if (data[form] !== undefined && data[form] !== "") return "active"
    else return ""
  }

  return (
    <Layout className="contact">
      <SEO title="Contact" />
      <Title
        isFull={true}
        title="Let’s talk"
        description="I am always happy to hear from you :)"
        status={
          serverState.status && (
            <small className={!serverState.status.ok ? "errorMsg" : "success"}>
              {serverState.status.msg}
            </small>
          )
        }
      />

      {/* FORM ACTIONS */}
      <div className="form-action">
        <Container>
          <Form onSubmit={handleOnSubmit}>
            <Form.Row>
              <Form.Group as={Col} md={6} className={`${activeForm("name")}`}>
                <Form.Control
                  name="name"
                  id="name"
                  type="text"
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <Form.Label htmlFor="name">Name</Form.Label>
                <span className="highlight"></span>
                <span className="bar"></span>
              </Form.Group>

              <Form.Group as={Col} md={6} className={`${activeForm("tel")}`}>
                <Form.Control
                  name="tel"
                  id="tel"
                  type="tel"
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <Form.Label htmlFor="tel">Phone</Form.Label>
                <span className="highlight"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </Form.Group>
            </Form.Row>
            <Form.Group className={`${activeForm("email")}`}>
              <Form.Control
                name="email"
                id="email"
                type="email"
                autoComplete="off"
                onChange={handleChange}
                required
              />
              <Form.Label htmlFor="email">Email</Form.Label>
              <span className="highlight"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </Form.Group>

            <Form.Group className={`${activeForm("message")}`}>
              <Form.Control
                className="text-area"
                name="message"
                id="textarea"
                as="textarea"
                autoComplete="off"
                onChange={handleChange}
                required
              />
              <Form.Label htmlFor="textarea">Message</Form.Label>
              <span className="highlight"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </Form.Group>

            <Button variant="primary" name="send" type="submit">
              Submit
            </Button>
          </Form>
          <ListGroup>
            <ListGroup.Item>
              Email : <em>info@jaydevanimator.com</em>
            </ListGroup.Item>
            <ListGroup.Item>
              Phone : <em>+971 5585 94468</em>
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </div>
    </Layout>
  )
}

export default Contact
